import React from 'react';
import styled from 'styled-components';

// Capitalize the component name to follow React conventions
function Footer(props) {
    return (
        <StyledFooter>
            <h1>
                <p>
                    Made for 2024 Google Cloud Arcade Facilitators & Students. - Developer <a href="https://www.linkedin.com/in/sahil-rakhaiya" target="_blank">Sahil Rakhaiya</a>
                </p>
            </h1>
        </StyledFooter>
    );
}

export default Footer;

// Styled component for the footer
const StyledFooter = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 40px auto;
    padding: 10px;
    box-sizing: border-box;

    p {
        text-align: center;
        font-size: 11px;
        margin: 0; // Optional: Remove default margin for <p>
    }

    h1 {
        margin: 0; // Optional: Remove default margin for <h1>
    }

`;
