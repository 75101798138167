import React, { useState } from 'react';
import styled from 'styled-components';

function StudentInfoWithCalculatePoints({ studentData }) {
    const [profileUrl, setProfileUrl] = useState('');
    const [totalPoints, setTotalPoints] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleCalculatePoints = async () => {
        if (!profileUrl) {
            setErrorMessage('Please enter a valid profile URL');
            return;
        }

        setErrorMessage('');
        setTotalPoints('Calculating...');

        try {
            const response = await fetch(`https://arcadehelper.tech/api/btecky-facilitator-event-ewwqw?url=${encodeURIComponent(profileUrl)}`, {
                headers: {
                    'x-api-key': 'FAC42-GCLOUD'
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch badge points');
            }

            const data = await response.json();
            setTotalPoints(`Total Points: ${data.totalPoints}`);

        } catch (error) {
            setErrorMessage('Error calculating points: ' + error.message);
        }
    };

    return (
        <StyledStudentInfo>
            <div 
                className="student-info-card"
                style={{
                    backgroundColor: studentData['Profile URL Status'] === "All Good" ? "#34a853" : '#EA4335'
                }}
            >
                <p className="student-info-card-title">Profile URL Status</p>
                <p className="student-info-card-status">{studentData['Profile URL Status']}</p>
            </div>
            <div className="student-info-card">
                <p className="student-info-card-title"># of Arcade Games Completed</p>
                <p className="student-info-card-number-value">{studentData['# of Arcade Games Completed']}</p>
            </div>
            <div className="student-info-card">
                <p className="student-info-card-title"># of Trivia Games Completed</p>
                <p className="student-info-card-number-value">{studentData['# of Trivia Games Completed']}</p>
            </div>
            <div className="student-info-card">
                <p className="student-info-card-title"># of Skill Badges Completed</p>
                <p className="student-info-card-number-value">{studentData['# of Skill Badges Completed']}</p>
            </div>
            <div className="student-info-card">
                <p className="student-info-card-title">Access Code Redemption Status</p>
                <p className="student-info-card-number-value">{studentData['Access Code Redemption Status']}</p>
            </div>
            <div className="student-info-card">
                <p className="student-info-card-title">Milestone Earned</p>
                <p className="student-info-card-number-value">{studentData['Milestone Earned']}</p>
            </div>

            <div className="spacer" />

            <StyledCalculatePoints>
                <h1>Arcade Point Calculator</h1>
                <input
                    type="text"
                    value={profileUrl}
                    onChange={e => setProfileUrl(e.target.value)}
                    placeholder="Enter public profile URL"
                />
                <button onClick={handleCalculatePoints}>Calculate Points</button>
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                <p className="total-points">{totalPoints}</p>
            </StyledCalculatePoints>
        </StyledStudentInfo>
    );
}

const StyledStudentInfo = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 95%;
    margin: 10px auto;

    .student-info-card {
        background-color: #4285F4;
        padding: 10px;
        border-radius: 10px;
        margin: 10px;
        max-width: 200px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

        .student-info-card-title {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            text-transform: uppercase;
            color: #FFFFFF;
            margin: 0;
            padding: 0;
        }

        .student-info-card-status,
        .student-info-card-number-value {
            font-style: normal;
            font-weight: 600;
            color: #FFFFFF;
            margin: 0;
            padding: 0;
            margin-top: 10px;
        }

        .student-info-card-status {
            font-size: 15px;
        }

        .student-info-card-number-value {
            font-size: 30px;
            line-height: 40px;
            font-weight: 800;
        }
    }

    .spacer {
        width: 100%;
        height: 20px; /* Adjust the height as needed for spacing */
    }
`;

const StyledCalculatePoints = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95%;
    margin: 20px auto;

    input {
        margin-bottom: 10px;
        padding: 8px;
        width: 300px;
        border: 1px solid #ddd;
        border-radius: 4px;
        font-size: 16px;
    }

    button {
        padding: 10px 20px;
        background-color: brown;
        color: white;
        border: none;
        border-radius: 10px;
        cursor: pointer;
        font-size: 16px;
        transition: background-color 0.3s ease;
    }

    button:hover {
        background-color:  #b62f2f;
    }

    button:disabled {
        background-color: #aaa;
        cursor: not-allowed;
    }

    .error-message {
        color: red;
        font-size: 14px;
        margin-top: 10px;
    }

    .total-points {
        font-size: 24px;
        font-weight: bold;
        margin-top: 10px;
    }
`;

export default StudentInfoWithCalculatePoints;
