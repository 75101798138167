import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import Milestone from './Milestone';
import StudentInfo from './StudentInfo';


function ProgressCard({ userStudentDetails }) {
    const completedArcadeBadges = parseInt(userStudentDetails["# of Arcade Games Completed"], 10);
    const completedSkillBadges = parseInt(userStudentDetails["# of Skill Badges Completed"], 10);
    const completedTriviaBadges = parseInt(userStudentDetails["# of Trivia Games Completed"], 10);

    // Clamp function to limit values between min and max
    const clamp = (val, min, max) => Math.max(min, Math.min(val, max));

    // Define milestones with their details and progress calculations
    const milestones = [
        {
            milestoneTitle: "Milestone #1 - Complete any 2 games, 2 trivia, & 8 skill badges in the program",
            milestoneCompletedArcadeBadges: Math.min(completedArcadeBadges, 2),
            milestoneCompletedTriviaBadges: Math.min(completedTriviaBadges, 2),
            milestoneCompletedSkillBadges: Math.min(completedSkillBadges, 8),
            hasMilestoneUnlocked: completedArcadeBadges >= 2 && completedSkillBadges >= 8 && completedTriviaBadges >= 2,
            maxArcadeBadges: 2,
            maxSkillBadges: 8,
            maxTriviaBadges: 2,
            milestoneColor: "#4285F4",
            completedText: "WOHOOO! WAY TO GO BUDDY! 🎉",
        },
        {
            milestoneTitle: "Milestone #2 - Complete any 3 games, 4 trivia, & 18 skill badges in the program",
            milestoneCompletedArcadeBadges: Math.min(completedArcadeBadges, 3),
            milestoneCompletedTriviaBadges: Math.min(completedTriviaBadges, 4),
            milestoneCompletedSkillBadges: Math.min(completedSkillBadges, 18),
            hasMilestoneUnlocked: completedArcadeBadges >= 3 && completedSkillBadges >= 18 && completedTriviaBadges >= 4,
            maxArcadeBadges: 3,
            maxSkillBadges: 18,
            maxTriviaBadges: 4,
            milestoneColor: "#FBBC05",
            completedText: "Keep up the good work! 🔥",
        },
        {
            milestoneTitle: "Milestone #3 - Complete any 5 games, 6 trivia, & 28 skill badges in the program",
            milestoneCompletedArcadeBadges: Math.min(completedArcadeBadges, 5),
            milestoneCompletedTriviaBadges: Math.min(completedTriviaBadges, 6),
            milestoneCompletedSkillBadges: Math.min(completedSkillBadges, 28),
            hasMilestoneUnlocked: completedArcadeBadges >= 5 && completedSkillBadges >= 28 && completedTriviaBadges >= 6,
            maxArcadeBadges: 5,
            maxSkillBadges: 28,
            maxTriviaBadges: 6,
            milestoneColor: "#34A853",
            completedText: "You are awesome! 🤩🔥",
        },
        {
            milestoneTitle: "Ultimate Milestone: Complete 6 games, 8 trivia, & 42 Skill Badges",
            milestoneCompletedArcadeBadges: Math.min(completedArcadeBadges, 6),
            milestoneCompletedTriviaBadges: Math.min(completedTriviaBadges, 8),
            milestoneCompletedSkillBadges: Math.min(completedSkillBadges, 42),
            hasMilestoneUnlocked: completedArcadeBadges >= 6 && completedSkillBadges >= 42 && completedTriviaBadges >= 8,
            maxArcadeBadges: 6,
            maxSkillBadges: 42,
            maxTriviaBadges: 8,
            milestoneColor: "#EA4335",
            completedText: "Congrats! You totally rock! 💯",
        }
    ];

    return (
        <StyledProgressCard
            initial={{ opacity: 0, translateX: 2000 }}
            animate={{ opacity: 1, translateX: 0 }}
            exit={{ opacity: 0, translateX: -2000 }}
            transition={{ delay: 0.5 }}
        >
            <h1 className="greet-student">Welcome, {userStudentDetails['User Name']}!</h1>
            <div className="progress-bars-container">
                {milestones.map((milestone, index) => {
                    // Calculate total required and completed badges
                    const totalRequiredBadges = milestone.maxArcadeBadges + milestone.maxSkillBadges + milestone.maxTriviaBadges;
                    const completedBadges = milestone.milestoneCompletedArcadeBadges + milestone.milestoneCompletedSkillBadges + milestone.milestoneCompletedTriviaBadges;
                    const milestoneProgressPercentage = clamp((completedBadges / totalRequiredBadges) * 100, 0, 100).toFixed(2);

                    return (
                        <Milestone
                            key={index}
                            milestoneData={{
                                ...milestone,
                                milestoneProgressPercentage,
                                milestoneCompletedBadges: completedBadges,
                                milestoneTotalRequiredBadges: totalRequiredBadges,
                            }}
                        />
                    );
                })}
            </div>
            <StudentInfo studentData={userStudentDetails} />
            <p className="quest-message">
                Game badges, Trivia badges, and Skill badges completed since your enrollment date will only be counted for progress.
            </p>
        </StyledProgressCard>
    );
}

export default ProgressCard;

const StyledProgressCard = styled(motion.div)`
    margin: 0 auto;
    background-color: #f4f4f4;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0px 4px 15px -3px rgba(0, 0, 0, 0.2);

    .greet-student {
        font-size: 24px;
        text-align: center;
        margin: 10px 0;
        font-weight: 600;
        text-transform: capitalize;
    }

    .progress-bars-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 20px;
    }

    .progress-item {
        display: flex;
        flex-direction: column;
        width: 280px;
        margin: 10px;

        .progress-title {
            font-size: 12px;
            font-weight: bold;
            text-transform: uppercase;
            color: #333;
        }

        .progress-bar {
            position: relative;
            border-radius: 30px;
            overflow: hidden;
            display: flex;
            align-items: center;
            padding: 5px;
            height: 20px;
            background-color: #f2f2f2;

            p {
                z-index: 1;
                font-weight: 500;
                font-size: 12px;
                color: #fff;
                margin: 0;
                padding: 0;
            }

            .progress-width {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
            }
        }

        .progress-info-container {
            margin-top: 10px;
            display: flex;
            justify-content: space-between;

            p {
                font-size: 12px;
                font-weight: 500;
                color: #333;
                margin: 0;
                padding: 0;
            }

            .progress-info {
                display: flex;
            }
        }
    }

    .quest-message {
        font-size: 12px;
        text-align: center;
        margin-top: 20px;
        color: #777;
    }

    @media only screen and (max-width: 450px) {
        .progress-item {
            width: 90%;
        }
    }
`;
